@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600&display=swap);
body {  
  background-color: #F9EEEE;
  font-family: Nunito;
  /* overflow: hidden; */
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4{
  margin: 0.2em 0;
}

h2{
  border-bottom: 1px solid #434242;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ----------- Utilities ---------------------------------*/

.container{
  max-width: 1050px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 40px;
}

.flex{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.block {
  display: block;
  padding: 1em;
  margin: 1em;
}

.grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-3{
  grid-template-columns: repeat(3, 1fr);
}

.card{
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.btn{
  display: inline-block;
  padding: 10px 30px;
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn:hover{
  transform: scale(.98);
}

.btn-outline{
  background-color: transparent;
  border: 1px #fff solid;
}

.sec1, .sec2 {    
  background-color: #F9EEEE;
  width: 100%;
  overflow: hidden;
  margin: 1em auto;
}

.sec2 {
  background-color: #ECE4E4;  
}


/* ---------------- Main Intro Section ---------------------------------*/

.intro{
  grid-template-columns: 1fr 3fr 2fr;
}

.intro-social{  
  flex-direction: column; 
  animation: slideInFromLeft 0.5s ease-in;  
}

.intro-social a{
  font-size: 0.75em;
  margin: 0.2em;
  text-decoration: none;
  color: #646464;
  padding: 0.5em;
}

.intro-social a:nth-last-child(1){
  border: 1px solid #646464;
  padding: 0.5em 0.6em;
  border-radius: 50%;
}

.main-intro{
  font-size: 1.4em;  
  animation: slideInFromLeft 0.5s ease-in;
  color: #535353;
}

.profile-pic img{
  animation: slideInFromRight 0.5s ease-in;
  border-radius: 50%;
  width: 16em;
  height: 16em;
  margin: 7em;
  z-index: 1;
}

/* ---------------- Achievements ---------------------------------------*/

.achievements{
  padding: 3em 0;
  flex-direction: column;
  justify-content: center;
}

.achieve{
  place-items: center;  
}

.avatar img{
  width: 20em;
  height: 20em;
  display: block;
}

.achievements-text p{
  padding: 1em;
  margin: 0.3em 1em;
  position: relative;
}

.achievements-text p::before{
  font-family: 'Font Awesome 5 Free';
  content: '\f058';
  position: absolute;
  left: -1em;
  top: 1.2em;
  color: #666877;   
}

/* ---------------- Past_experience ------------------------------------*/

.past-exp{
  flex-direction: column;  
  padding: 1em;
}

.past-exp h3{
  border-bottom: 1px solid #333;  
}

/* ---------------- Accordian  ------------------------------------------*/

.tweets{
  flex-direction: column;
  padding: 1em;
}

.tweets h2{
  margin: 1em 0;
}

.main{ 
  height: 77vh;
}

/* ---------------- Animations  ------------------------------------------*/

@keyframes slideInFromLeft {
  0% {
      transform: translateX(-100px);
  }
  100% {
      transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
      transform: translateX(100px);
  }
  100% {
      transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
      transform: translateY(-100px);
  }
  100% {
      transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
      transform: translateY(100px);
  }
  100% {
      transform: translateY(0);
  }
}



/*==============================================*/
/*---------------- Media Queries ---------------*/
/*==============================================*/

/*==============================================*/
/*-------   Max-Screeen-Size : 345px     -------*/
/*==============================================*/

@media only screen and (max-width: 345px) {

  .homepage{
    margin-top: 12vh;
    padding-top: 2vh;
  }
  
  .intro{
    max-width: unset;
    width: 100%;
    margin: 2em auto;
    grid-gap: 3em 10px;
    gap: 3em 10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "main-intro  main-intro  main-intro"
    "intro-social  profile-pic .";
    
  }
  
  .intro-social{  
    grid-area: intro-social;
  }
  
  .main-intro{
    grid-area: main-intro;    
    text-align: center;
  }
  
  .main-intro h1{
    border-bottom: 1px solid #535353;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 2px auto;
  }

  .main-intro h6{
    margin-top: 10px;
  }
  
  .profile-pic{
    grid-area: profile-pic;
    margin: 0 auto;
  }
  .profile-pic img{
    border: 10px solid #dddddd ;
    width: 10em;
    height: 10em;
    margin: 0;
    z-index: 1;
  }

  .achievements{
    width: 85%;
    padding: 1em;
  } 
  
  .achievements-text{
    padding-left: 2em;
  }

  .achieve{
    grid-template-columns: 1fr;
  }

  .avatar img{    
    display: none;
  }
  
}

/*==============================================*/
/*-------   Min-Screeen-Size : 346px     -------*/
/*==============================================*/

@media only screen and (min-width: 346px) {
  .homepage{
    margin-top: 12vh;
    padding-top: 2vh;
  }
  
  .intro{
    max-width: unset;
    width: 100%;
    margin: 2em auto;
    grid-gap: 3em 10px;
    gap: 3em 10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "main-intro  main-intro  main-intro"
    "intro-social  profile-pic .";
    
  }
  
  .intro-social{  
    grid-area: intro-social;
  }
  
  .main-intro{
    grid-area: main-intro;    
    text-align: center;
  }
  
  .main-intro h1{
    border-bottom: 1px solid #535353;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 2px auto;
  }

  .main-intro h6{
    margin-top: 10px;
  }
  
  .profile-pic{
    grid-area: profile-pic;
    margin: 0 auto;
  }
  .profile-pic img{
    border: 10px solid #dddddd ;
    width: 10em;
    height: 10em;
    margin: 0;
    z-index: 1;
  }

  .achievements{
    width: 85%;
    padding: 1em;
  } 
  
  .achievements-text{
    padding-left: 2em;
  }

  .achieve{
    grid-template-columns: 1fr;
  }

  .avatar img{    
    display: none;
  }

}




/*==============================================*/
/*-------   Min-Screeen-Size : 400px     -------*/
/*==============================================*/

@media only screen and (min-width: 400px) {

}



/*==============================================*/
/*-------   Min-Screeen-Size : 600px     -------*/
/*==============================================*/

@media only screen and (min-width: 600px) {

}




/*==============================================*/
/*-------   Min-Screeen-Size : 768px     -------*/
/*==============================================*/

@media only screen and (min-width: 768px) {

  .achievements{
    padding: 3em 0;
  }

  .achieve{
    grid-template-columns: 1fr 1fr;
  }

  .avatar img{    
    display: block;
  }

}


/*==============================================*/
/*-------   Min-Screeen-Size : 1200px    -------*/
/*==============================================*/

@media only screen and (min-width: 1200px) {

  .intro{
    max-width: 1100px;
    width: 100%;
    margin: 2em auto;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: 1fr 3fr 2fr;
    grid-template-areas:
    "intro-social  main-intro  profile-pic";
  }

  .main-intro {
    text-align: left;
  }

  .main-intro h1{
    border-bottom: unset;
    margin: unset;
  }

  .profile-pic img{  
    width: 16em;
    height: 16em;
    margin: 7em;
  }

}

.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.outer_shell{  
    flex-direction: column;
    margin: 2em;
}
  
  .accordian{
    background-color: #e4f0f1;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    grid-template-columns: 2fr 4fr 1fr;
    /* place-items: center;                 --> Centers anything inside a grid container*/        
    position: relative;
    width: 90%;
    margin: 0.3em;
  }
  
  .accordian p:nth-child(1){
    padding: 2.5em;
    display: flex;
    align-self: flex-start;
  }
  
.accordian button
{
    background-image: url(/static/media/down.a4f409dd.svg);
    position: absolute;
    background-color: transparent;
    border: none;
    width: 0.9em;
    height: 1em;
    color: #333;
    top: 4em;
    right: 5em;
    outline: none;
    cursor: pointer;
    /* display: inline;   */
}
  
  /* .accordion_rotated_btn{
    background-image: url(../../assets/up.svg) !important;
  } */
  
.accordion_contents{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;  
    transition: height 0.6s ease-in;
}
  
.accordion_nocontents{
    display: none;
    transition: height 0.6s ease-in;
}
  
.accordion_contents h5{
    margin: 0.5em 0;
    color: #524d4d;
    background-color: #e0dddd;
    border-radius: 5px;
    padding: 5px;
}
  
.accordion_contents p{
    color: #222020;
}

.past_period{
    display: flex;
}

.period_delimiter::after{
    content: " - ";
    padding: 0 5px;
}

/*==============================================*/
/*---------------- Media Queries ---------------*/
/*==============================================*/

/*==============================================*/
/*-------   Max-Screeen-Size : 345px     -------*/
/*==============================================*/

@media only screen and (max-width: 345px) {

    .accordian{
        grid-template-columns: 1fr 3fr 1fr;
        grid-gap: 0px;
        gap: 0px;
        font-size: 0.9em;
    }
    
    .period_delimiter::after{
        content: "|";
    }
    
    .past_period{        
       flex-direction: column;
       text-align: center;
    }

    .accordian button
    {      
      right: 1.2em;
    }

    .accordian p:nth-child(1){
        padding: 2.5em 1.5em;
    }

    .block{
        padding: 1em 0;
        margin: 1em 0;
    }
  
}

/*==============================================*/
/*-------   Min-Screeen-Size : 346px     -------*/
/*==============================================*/

@media only screen and (min-width: 346px) {
 
    .accordian{
        grid-template-columns: 1fr 3fr 1fr;
        grid-gap: 0px;
        gap: 0px;
        font-size: 0.9em;
    }
    
    .period_delimiter::after{
        content: "|";
    }
    
    .past_period{        
       flex-direction: column;
       text-align: center;
    }

    .accordian button
    {      
      right: 1.2em;
    }

    .accordian p:nth-child(1){
        padding: 2.5em 1.5em;
    }

    .block{
        padding: 1em 0;
        margin: 1em 0;
    }
}




/*==============================================*/
/*-------   Min-Screeen-Size : 400px     -------*/
/*==============================================*/

@media only screen and (min-width: 400px) {

    .accordian{
        grid-template-columns: 2fr 3fr 1fr;       
    }
}



/*==============================================*/
/*-------   Min-Screeen-Size : 600px     -------*/
/*==============================================*/

@media only screen and (min-width: 600px) {

}




/*==============================================*/
/*-------   Min-Screeen-Size : 768px     -------*/
/*==============================================*/

@media only screen and (min-width: 768px) {

    .past_period{
        flex-direction: row;
      }
    
    .period_delimiter::after{
        content: " - ";
        padding: 0 5px;
    }

    .accordian{
        grid-template-columns: 3fr 4fr 1fr;
        font-size: 1em;
        grid-gap: 1em;
        gap: 1em;
    }

    .accordian button
    {
        right: 3em;    
    }

}


/*==============================================*/
/*-------   Min-Screeen-Size : 1024px     -------*/
/*==============================================*/

@media only screen and (min-width: 1024px) {
    .accordian p:nth-child(1){
        padding: 2.5em;
    }

    .accordian{
        grid-template-columns: 2fr 4fr 1fr;
        grid-gap: 1.5em;
        gap: 1.5em;
    }

    
}



/*==============================================*/
/*-------   Min-Screeen-Size : 1200px    -------*/
/*==============================================*/

@media only screen and (min-width: 1200px) {
    
    
}


nav, .navbar{  
    display: flex;
    font-family: Ubuntu;
    justify-content: space-between;
    margin: 0 auto;
    height: 4em;
    width: 100%;
    font-weight: 400;    
    align-items: center;
    z-index: 15;    
}
  
.navbar{      
    height: 7vh;
    width: 100%;
    font-weight: 400;
    margin: 0 auto;
    align-items: center;
    z-index: 15;
    position: fixed;
    top: 0px;
    background-color: #F9EEEE;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); */
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 7vh;
    max-width: 1100px;
    margin: 0 auto;
    top: 0px;
    position: relative;
}

.Logo a{
    /* font-size: 1.4em; */
    text-decoration: none;
    font-weight: 400;
    color: #434242;
    background: transparent;
}
  
.NavLinks {  
    display: flex;
    font-size: 1em;
    list-style: none;
}

.NavLinks li{
    padding: 0.5em 2em;
    margin: 0 auto;    
}

.NavLinks a {
    text-decoration: none;
    color: #7C7A7A;
}

.NavLinks a:hover, .pageactive{
    padding: 1em 0;
    border-bottom: 1px solid #7698F0;
    color: #83a1ee;  
}

.nav-icon {
    color: grey;
    font-size: 1.3rem;
    display: none;
    position: absolute;
    right: 0px;
    z-index: 20;
}


/*==============================================*/
/*---------------- Media Queries ---------------*/
/*==============================================*/

/*==============================================*/
/*-------   Max-Screeen-Size : 345px     -------*/
/*==============================================*/

@media only screen and (max-width: 345px) {

    .navbar{
        height: 12vh;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); */
    }

    .nav-container {
        width: 100%;
        margin: 0 2em;
        height: 12vh;
        max-width: unset;        
    }
    
    .NavLinks{        
        display: flex;
        flex-direction: column;
        list-style: none;
        position: absolute;
        width: 60%;
        right: -100%;
        height: 100vh;    
        top: 0px;        
        justify-content: space-around;
        padding: 2em 0;
        align-items: center;
        transform: translateX(100%);
        transition: all 0.5s ease;  
        opacity: 1;          
    }
      
    .active{
        background: #ECE4E4;
        right: 50%;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;          
    }
    
    .nav-icon{
        display: block;
    }
  }
  
  /*==============================================*/
  /*-------   Min-Screeen-Size : 346px     -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 346px) {
    
    .navbar{
        height: 12vh;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); */
    }

    .nav-container {
        width: 100%;
        margin: 0 2em;
        height: 12vh;
        max-width: unset;        
    }
    
    .NavLinks{        
        display: flex;
        flex-direction: column;
        list-style: none;
        position: absolute;
        width: 60%;
        right: -100%;
        height: 100vh;    
        top: 0px;        
        /* justify-content: space-around; */
        padding: 2em 0;
        align-items: center;
        transform: translateX(100%);
        transition: all 0.5s ease;  
        opacity: 1;          
        padding-top: 5em;
    }

    .NavLinks li{
        margin: 1.5em auto;
    }
      
    .active{
        background: #ECE4E4;
        right: 50%;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;          
    }

    .nav-icon{
        display: block;
    }
  
  }
  
  
  
  
  /*==============================================*/
  /*-------   Min-Screeen-Size : 400px     -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 400px) {
  
  }
  
  
  
  /*==============================================*/
  /*-------   Min-Screeen-Size : 600px     -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 600px) {
  
  }
  
  
  
  
  /*==============================================*/
  /*-------   Min-Screeen-Size : 768px     -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 768px) {
  
  
  }
  
  
  /*==============================================*/
  /*-------   Min-Screeen-Size : 1024px    -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 1024px) {
  
    .nav-container{
        height: 7vh;
        max-width: 900px;
        margin: 0 auto;
    }

    .NavLinks {  
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 40vw;
        height: 7vh;
        padding: 1em;
        font-size: 1em;
        list-style: none;
        transform: translateX(0%);
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .active{
        background: #F9EEEE;        
    }

    .nav-icon{
        display: none;
    }
      
  }
  
    /*==============================================*/
  /*-------   Min-Screeen-Size : 1200px     -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 1200px) {
  
    .nav-container{
        max-width: 1100px;
    }
}
.footer{
    width: 100%;
    padding: 5em;
    background-color: #333;
  }
  
  .footer .container {
    grid-template-columns: 4fr 2fr;
    grid-gap: 10em;
    gap: 10em;
  }
  
  .footer-nav{
    margin: 0;
    width: 100%;
    justify-content: space-around;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .footer-links li{
    list-style: none;
    width: 12em;
    padding: 6px;
  } 
  
  .footer-links li:hover{
    background-color: #524d4d;
    border-radius: 4px;
  } 
  
  .footer-links a {
    text-decoration: none;
    color: #e4f0f1;
  }
  
  .social-links{
    align-items: flex-start;
  }
  
  .social-links li{
    font-size: 1.3em;
    cursor: pointer;
    list-style: none;
    margin: 0.5em;
  }
  .social-links a{
    color: #dad3d3;
  }
  .social-links a:hover{
    color: #c4bebe;
  }
  
  .separator{
    background: #dad3d3;
    border: none;
    height: 1px;
    margin-top: 3em;
    margin-bottom: 0;
  }


/*==============================================*/
/*---------------- Media Queries ---------------*/
/*==============================================*/

/*==============================================*/
/*-------   Max-Screeen-Size : 345px     -------*/
/*==============================================*/

@media only screen and (max-width: 345px) {

  .footer{
    padding: 2em 0.5em;
    font-size: 0.9em;
  }

  .footer-links li{
    width: 8em;
    padding: 6px 0;
  } 

  .footer .container {
    grid-template-columns: 4fr 1fr;    
    grid-gap: 0;    
    gap: 0;
    /* padding: 1em; */
  }

  .social-links{
    flex-direction: column;
    align-items: flex-end;
    padding: 1em;
  }

  .social-links li{
    margin: 0.1em;
  }
}

/*==============================================*/
/*-------   Min-Screeen-Size : 346px     -------*/
/*==============================================*/

@media only screen and (min-width: 346px) {
 
  .footer{
    padding: 2em 0.5em;
    font-size: 0.9em;
  }

  .footer-links li{
    width: 8em;
    padding: 6px 0;
  } 

  .footer .container {
    grid-template-columns: 4fr 1fr;    
    grid-gap: 0;    
    gap: 0;
    /* padding: 1em; */
  }

  .social-links{
    flex-direction: column;
    align-items: flex-end;
    padding: 1em;
  }

  .social-links li{
    margin: 0.1em;
  }

}


/*==============================================*/
/*-------   Min-Screeen-Size : 400px     -------*/
/*==============================================*/

@media only screen and (min-width: 400px) {

}



/*==============================================*/
/*-------   Min-Screeen-Size : 600px     -------*/
/*==============================================*/

@media only screen and (min-width: 600px) {

}


/*==============================================*/
/*-------   Min-Screeen-Size : 768px     -------*/
/*==============================================*/

@media only screen and (min-width: 768px) {

    .footer .container {
      grid-template-columns: 4fr 2fr;
      grid-gap: 0;
      gap: 0;
    }

    .footer{
      padding: 5em;
      font-size: 1em;
    }
    
    .footer-links li{      
      width: 12em;
      padding: 6px;
    } 
    
    .social-links{
      flex-direction: row;
      align-items: flex-start;
    }

    .social-links li{
        margin: 0.5em;
    }

}
  


/*==============================================*/
/*-------   Min-Screeen-Size : 1200px    -------*/
/*==============================================*/

@media only screen and (min-width: 1200px) {

    .footer .container {
      grid-gap: 10em;
      gap: 10em;
    }
}
.progress-bar {
    height: 20px;
    background: #8b8a5a;
    border-radius: 15px;
}
.Upload-form{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15em auto;
    margin-top: 15em;
}

.error, .file_name{
    color: red;
    font-weight: 900;
    letter-spacing: 2px;
    font-size: 1.5em;
}

input {
    opacity: 0;
}

.file_name{
    color: blue;
    margin-top: 1em;
}

label {
    display: flex;
    position: relative;
    justify-content: center;
    background-color: red;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid #432343;
    border-radius: 50%;
    margin: 10px auto;
    line-height: 2em;
    font-size: 2em;
    font-weight: bold;
    cursor: pointer;
}

label:hover{
    color: white;
}

.span{
    position: absolute;
}

.progress_container {
    height: 20px;
    width: 100%;
    background: #d6d6c9;
    border-radius: 15px;
    margin: 1em 0;
}

