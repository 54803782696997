.footer{
    width: 100%;
    padding: 5em;
    background-color: #333;
  }
  
  .footer .container {
    grid-template-columns: 4fr 2fr;
    gap: 10em;
  }
  
  .footer-nav{
    margin: 0;
    width: 100%;
    justify-content: space-around;
    height: fit-content;
  }
  
  .footer-links li{
    list-style: none;
    width: 12em;
    padding: 6px;
  } 
  
  .footer-links li:hover{
    background-color: #524d4d;
    border-radius: 4px;
  } 
  
  .footer-links a {
    text-decoration: none;
    color: #e4f0f1;
  }
  
  .social-links{
    align-items: flex-start;
  }
  
  .social-links li{
    font-size: 1.3em;
    cursor: pointer;
    list-style: none;
    margin: 0.5em;
  }
  .social-links a{
    color: #dad3d3;
  }
  .social-links a:hover{
    color: #c4bebe;
  }
  
  .separator{
    background: #dad3d3;
    border: none;
    height: 1px;
    margin-top: 3em;
    margin-bottom: 0;
  }


/*==============================================*/
/*---------------- Media Queries ---------------*/
/*==============================================*/

/*==============================================*/
/*-------   Max-Screeen-Size : 345px     -------*/
/*==============================================*/

@media only screen and (max-width: 345px) {

  .footer{
    padding: 2em 0.5em;
    font-size: 0.9em;
  }

  .footer-links li{
    width: 8em;
    padding: 6px 0;
  } 

  .footer .container {
    grid-template-columns: 4fr 1fr;    
    gap: 0;
    /* padding: 1em; */
  }

  .social-links{
    flex-direction: column;
    align-items: flex-end;
    padding: 1em;
  }

  .social-links li{
    margin: 0.1em;
  }
}

/*==============================================*/
/*-------   Min-Screeen-Size : 346px     -------*/
/*==============================================*/

@media only screen and (min-width: 346px) {
 
  .footer{
    padding: 2em 0.5em;
    font-size: 0.9em;
  }

  .footer-links li{
    width: 8em;
    padding: 6px 0;
  } 

  .footer .container {
    grid-template-columns: 4fr 1fr;    
    gap: 0;
    /* padding: 1em; */
  }

  .social-links{
    flex-direction: column;
    align-items: flex-end;
    padding: 1em;
  }

  .social-links li{
    margin: 0.1em;
  }

}


/*==============================================*/
/*-------   Min-Screeen-Size : 400px     -------*/
/*==============================================*/

@media only screen and (min-width: 400px) {

}



/*==============================================*/
/*-------   Min-Screeen-Size : 600px     -------*/
/*==============================================*/

@media only screen and (min-width: 600px) {

}


/*==============================================*/
/*-------   Min-Screeen-Size : 768px     -------*/
/*==============================================*/

@media only screen and (min-width: 768px) {

    .footer .container {
      grid-template-columns: 4fr 2fr;
      gap: 0;
    }

    .footer{
      padding: 5em;
      font-size: 1em;
    }
    
    .footer-links li{      
      width: 12em;
      padding: 6px;
    } 
    
    .social-links{
      flex-direction: row;
      align-items: flex-start;
    }

    .social-links li{
        margin: 0.5em;
    }

}
  


/*==============================================*/
/*-------   Min-Screeen-Size : 1200px    -------*/
/*==============================================*/

@media only screen and (min-width: 1200px) {

    .footer .container {
      gap: 10em;
    }
}