.Upload-form{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15em auto;
    margin-top: 15em;
}

.error, .file_name{
    color: red;
    font-weight: 900;
    letter-spacing: 2px;
    font-size: 1.5em;
}

input {
    opacity: 0;
}

.file_name{
    color: blue;
    margin-top: 1em;
}

label {
    display: flex;
    position: relative;
    justify-content: center;
    background-color: red;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid #432343;
    border-radius: 50%;
    margin: 10px auto;
    line-height: 2em;
    font-size: 2em;
    font-weight: bold;
    cursor: pointer;
}

label:hover{
    color: white;
}

.span{
    position: absolute;
}

.progress_container {
    height: 20px;
    width: 100%;
    background: #d6d6c9;
    border-radius: 15px;
    margin: 1em 0;
}
