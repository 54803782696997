@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600&display=swap');

body {  
  background-color: #F9EEEE;
  font-family: Nunito;
  /* overflow: hidden; */
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4{
  margin: 0.2em 0;
}

h2{
  border-bottom: 1px solid #434242;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ----------- Utilities ---------------------------------*/

.container{
  max-width: 1050px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 40px;
}

.flex{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.block {
  display: block;
  padding: 1em;
  margin: 1em;
}

.grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.grid-3{
  grid-template-columns: repeat(3, 1fr);
}

.card{
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.btn{
  display: inline-block;
  padding: 10px 30px;
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn:hover{
  transform: scale(.98);
}

.btn-outline{
  background-color: transparent;
  border: 1px #fff solid;
}

.sec1, .sec2 {    
  background-color: #F9EEEE;
  width: 100%;
  overflow: hidden;
  margin: 1em auto;
}

.sec2 {
  background-color: #ECE4E4;  
}


/* ---------------- Main Intro Section ---------------------------------*/

.intro{
  grid-template-columns: 1fr 3fr 2fr;
}

.intro-social{  
  flex-direction: column; 
  animation: slideInFromLeft 0.5s ease-in;  
}

.intro-social a{
  font-size: 0.75em;
  margin: 0.2em;
  text-decoration: none;
  color: #646464;
  padding: 0.5em;
}

.intro-social a:nth-last-child(1){
  border: 1px solid #646464;
  padding: 0.5em 0.6em;
  border-radius: 50%;
}

.main-intro{
  font-size: 1.4em;  
  animation: slideInFromLeft 0.5s ease-in;
  color: #535353;
}

.profile-pic img{
  animation: slideInFromRight 0.5s ease-in;
  border-radius: 50%;
  width: 16em;
  height: 16em;
  margin: 7em;
  z-index: 1;
}

/* ---------------- Achievements ---------------------------------------*/

.achievements{
  padding: 3em 0;
  flex-direction: column;
  justify-content: center;
}

.achieve{
  place-items: center;  
}

.avatar img{
  width: 20em;
  height: 20em;
  display: block;
}

.achievements-text p{
  padding: 1em;
  margin: 0.3em 1em;
  position: relative;
}

.achievements-text p::before{
  font-family: 'Font Awesome 5 Free';
  content: '\f058';
  position: absolute;
  left: -1em;
  top: 1.2em;
  color: #666877;   
}

/* ---------------- Past_experience ------------------------------------*/

.past-exp{
  flex-direction: column;  
  padding: 1em;
}

.past-exp h3{
  border-bottom: 1px solid #333;  
}

/* ---------------- Accordian  ------------------------------------------*/

.tweets{
  flex-direction: column;
  padding: 1em;
}

.tweets h2{
  margin: 1em 0;
}

.main{ 
  height: 77vh;
}

/* ---------------- Animations  ------------------------------------------*/

@keyframes slideInFromLeft {
  0% {
      transform: translateX(-100px);
  }
  100% {
      transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
      transform: translateX(100px);
  }
  100% {
      transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
      transform: translateY(-100px);
  }
  100% {
      transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
      transform: translateY(100px);
  }
  100% {
      transform: translateY(0);
  }
}



/*==============================================*/
/*---------------- Media Queries ---------------*/
/*==============================================*/

/*==============================================*/
/*-------   Max-Screeen-Size : 345px     -------*/
/*==============================================*/

@media only screen and (max-width: 345px) {

  .homepage{
    margin-top: 12vh;
    padding-top: 2vh;
  }
  
  .intro{
    max-width: unset;
    width: 100%;
    margin: 2em auto;
    gap: 3em 10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "main-intro  main-intro  main-intro"
    "intro-social  profile-pic .";
    
  }
  
  .intro-social{  
    grid-area: intro-social;
  }
  
  .main-intro{
    grid-area: main-intro;    
    text-align: center;
  }
  
  .main-intro h1{
    border-bottom: 1px solid #535353;
    width: fit-content;
    margin: 2px auto;
  }

  .main-intro h6{
    margin-top: 10px;
  }
  
  .profile-pic{
    grid-area: profile-pic;
    margin: 0 auto;
  }
  .profile-pic img{
    border: 10px solid #dddddd ;
    width: 10em;
    height: 10em;
    margin: 0;
    z-index: 1;
  }

  .achievements{
    width: 85%;
    padding: 1em;
  } 
  
  .achievements-text{
    padding-left: 2em;
  }

  .achieve{
    grid-template-columns: 1fr;
  }

  .avatar img{    
    display: none;
  }
  
}

/*==============================================*/
/*-------   Min-Screeen-Size : 346px     -------*/
/*==============================================*/

@media only screen and (min-width: 346px) {
  .homepage{
    margin-top: 12vh;
    padding-top: 2vh;
  }
  
  .intro{
    max-width: unset;
    width: 100%;
    margin: 2em auto;
    gap: 3em 10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "main-intro  main-intro  main-intro"
    "intro-social  profile-pic .";
    
  }
  
  .intro-social{  
    grid-area: intro-social;
  }
  
  .main-intro{
    grid-area: main-intro;    
    text-align: center;
  }
  
  .main-intro h1{
    border-bottom: 1px solid #535353;
    width: fit-content;
    margin: 2px auto;
  }

  .main-intro h6{
    margin-top: 10px;
  }
  
  .profile-pic{
    grid-area: profile-pic;
    margin: 0 auto;
  }
  .profile-pic img{
    border: 10px solid #dddddd ;
    width: 10em;
    height: 10em;
    margin: 0;
    z-index: 1;
  }

  .achievements{
    width: 85%;
    padding: 1em;
  } 
  
  .achievements-text{
    padding-left: 2em;
  }

  .achieve{
    grid-template-columns: 1fr;
  }

  .avatar img{    
    display: none;
  }

}




/*==============================================*/
/*-------   Min-Screeen-Size : 400px     -------*/
/*==============================================*/

@media only screen and (min-width: 400px) {

}



/*==============================================*/
/*-------   Min-Screeen-Size : 600px     -------*/
/*==============================================*/

@media only screen and (min-width: 600px) {

}




/*==============================================*/
/*-------   Min-Screeen-Size : 768px     -------*/
/*==============================================*/

@media only screen and (min-width: 768px) {

  .achievements{
    padding: 3em 0;
  }

  .achieve{
    grid-template-columns: 1fr 1fr;
  }

  .avatar img{    
    display: block;
  }

}


/*==============================================*/
/*-------   Min-Screeen-Size : 1200px    -------*/
/*==============================================*/

@media only screen and (min-width: 1200px) {

  .intro{
    max-width: 1100px;
    width: 100%;
    margin: 2em auto;
    gap: 20px;
    grid-template-columns: 1fr 3fr 2fr;
    grid-template-areas:
    "intro-social  main-intro  profile-pic";
  }

  .main-intro {
    text-align: left;
  }

  .main-intro h1{
    border-bottom: unset;
    margin: unset;
  }

  .profile-pic img{  
    width: 16em;
    height: 16em;
    margin: 7em;
  }

}
