
nav, .navbar{  
    display: flex;
    font-family: Ubuntu;
    justify-content: space-between;
    margin: 0 auto;
    height: 4em;
    width: 100%;
    font-weight: 400;    
    align-items: center;
    z-index: 15;    
}
  
.navbar{      
    height: 7vh;
    width: 100%;
    font-weight: 400;
    margin: 0 auto;
    align-items: center;
    z-index: 15;
    position: fixed;
    top: 0px;
    background-color: #F9EEEE;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); */
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 7vh;
    max-width: 1100px;
    margin: 0 auto;
    top: 0px;
    position: relative;
}

.Logo a{
    /* font-size: 1.4em; */
    text-decoration: none;
    font-weight: 400;
    color: #434242;
    background: transparent;
}
  
.NavLinks {  
    display: flex;
    font-size: 1em;
    list-style: none;
}

.NavLinks li{
    padding: 0.5em 2em;
    margin: 0 auto;    
}

.NavLinks a {
    text-decoration: none;
    color: #7C7A7A;
}

.NavLinks a:hover, .pageactive{
    padding: 1em 0;
    border-bottom: 1px solid #7698F0;
    color: #83a1ee;  
}

.nav-icon {
    color: grey;
    font-size: 1.3rem;
    display: none;
    position: absolute;
    right: 0px;
    z-index: 20;
}


/*==============================================*/
/*---------------- Media Queries ---------------*/
/*==============================================*/

/*==============================================*/
/*-------   Max-Screeen-Size : 345px     -------*/
/*==============================================*/

@media only screen and (max-width: 345px) {

    .navbar{
        height: 12vh;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); */
    }

    .nav-container {
        width: 100%;
        margin: 0 2em;
        height: 12vh;
        max-width: unset;        
    }
    
    .NavLinks{        
        display: flex;
        flex-direction: column;
        list-style: none;
        position: absolute;
        width: 60%;
        right: -100%;
        height: 100vh;    
        top: 0px;        
        justify-content: space-around;
        padding: 2em 0;
        align-items: center;
        transform: translateX(100%);
        transition: all 0.5s ease;  
        opacity: 1;          
    }
      
    .active{
        background: #ECE4E4;
        right: 50%;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;          
    }
    
    .nav-icon{
        display: block;
    }
  }
  
  /*==============================================*/
  /*-------   Min-Screeen-Size : 346px     -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 346px) {
    
    .navbar{
        height: 12vh;
        /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); */
    }

    .nav-container {
        width: 100%;
        margin: 0 2em;
        height: 12vh;
        max-width: unset;        
    }
    
    .NavLinks{        
        display: flex;
        flex-direction: column;
        list-style: none;
        position: absolute;
        width: 60%;
        right: -100%;
        height: 100vh;    
        top: 0px;        
        /* justify-content: space-around; */
        padding: 2em 0;
        align-items: center;
        transform: translateX(100%);
        transition: all 0.5s ease;  
        opacity: 1;          
        padding-top: 5em;
    }

    .NavLinks li{
        margin: 1.5em auto;
    }
      
    .active{
        background: #ECE4E4;
        right: 50%;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;          
    }

    .nav-icon{
        display: block;
    }
  
  }
  
  
  
  
  /*==============================================*/
  /*-------   Min-Screeen-Size : 400px     -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 400px) {
  
  }
  
  
  
  /*==============================================*/
  /*-------   Min-Screeen-Size : 600px     -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 600px) {
  
  }
  
  
  
  
  /*==============================================*/
  /*-------   Min-Screeen-Size : 768px     -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 768px) {
  
  
  }
  
  
  /*==============================================*/
  /*-------   Min-Screeen-Size : 1024px    -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 1024px) {
  
    .nav-container{
        height: 7vh;
        max-width: 900px;
        margin: 0 auto;
    }

    .NavLinks {  
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 40vw;
        height: 7vh;
        padding: 1em;
        font-size: 1em;
        list-style: none;
        transform: translateX(0%);
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .active{
        background: #F9EEEE;        
    }

    .nav-icon{
        display: none;
    }
      
  }
  
    /*==============================================*/
  /*-------   Min-Screeen-Size : 1200px     -------*/
  /*==============================================*/
  
  @media only screen and (min-width: 1200px) {
  
    .nav-container{
        max-width: 1100px;
    }
}